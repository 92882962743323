<template>
  <div>
    <index-page></index-page>
  </div>

</template>

<script>
import IndexPage from '@/views/system/user/index'
export default {
  components:{
    IndexPage
  },
  data() {
    return {}
  },

  created() {},
  methods: {},
}
</script>

<style lang="css" scoped>
</style>
