import request from '@/plugins/axios'

export function getProvince(){
  return request({
    url: 'city/province',
    method: 'GET'
  })
}
export function getCitys(provinceNo){
  return request({
    url: `city/provinceNo/${provinceNo}`,
    method: 'GET'
  })
}
