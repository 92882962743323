<template>
  <div style="height: 600px;overflow-y: auto;padding-right: 25px">
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <div style="display: flex">
        <el-form-item label="登录账号" style="flex: 1" prop="userAccount">
          <el-input v-model="form.userAccount" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item label="密码" style="flex: 1" prop="newPwd">
          <div style="display: flex">
            <el-input
              v-model="form.newPwd"
              disabled
              placeholder="请生成登录密码"
            />
            <el-button @click="randomPassword(6)">生成密码</el-button>
          </div>
        </el-form-item>
      </div>

      <div style="display: flex">
        <el-form-item label="用户名" style="flex: 1">
          <el-input
            v-model="form.userName"
            :disabled="id !== 0"
            placeholder="请输入用户名"
          />
        </el-form-item>
        <el-form-item label="反查数" style="flex: 1" prop="blastPhoneCount">
          <el-input
            v-model="form.blastPhoneCount"
            placeholder="请填写每日反查限制条数"
          />
        </el-form-item>
        <el-form-item label="导出总数" style="flex: 1">
          <el-input
            v-model="form.exportChannelCount"
            placeholder="请填写导出群组总数"
          />
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="到期日期" style="flex: 1" prop="expireTime">
          <el-date-picker
            v-model="form.expireTime"
            type="date"
            placeholder="选择到期日期"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
      </div>

      <div style="display: flex">
        <el-form-item label="测试账号" style="flex: 1">
          <el-switch
            v-model="form.isTest"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>

        <el-form-item label="加密狗" style="flex: 1">
          <el-switch
            v-model="form.dogcheck"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="角色" prop="userLevel">
          <el-radio-group v-model="form.userLevel">
            <el-radio :label="0" border>超级管理员</el-radio>
            <el-radio :label="1" border>用户管理员</el-radio>
            <el-radio :label="2" border>普通用户</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div v-if="form.dogcheck === 1" style="display: flex">
        <el-form-item label="key1" style="flex: 1" prop="key1">
          <el-input v-model="form.key1" />
        </el-form-item>
        <el-form-item label="key2" style="flex: 1" prop="key2">
          <el-input v-model="form.key2" />
        </el-form-item>
        <el-form-item label="key3" style="flex: 1" prop="key3">
          <el-input v-model="form.key3" />
        </el-form-item>
        <el-form-item label="key4" style="flex: 1" prop="key4">
          <el-input v-model="form.key4" />
        </el-form-item>
      </div>
      <div style="display: flex" v-if="form.userLevel === 1">
        <el-form-item label="用户数" style="flex: 1" prop="canCreateUserNum">
          <el-input
            v-model="form.canCreateUserNum"
            placeholder="请填写可创建用户数"
          />
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item
          label="归属省"
          style="flex: 1;width: 100%"
          prop="province"
        >
          <el-select
            v-model="form.province"
            placeholder="请选择活动区域"
            @change="getCity"
            clearable
          >
            <el-option
              v-for="item in provinces"
              :label="item.province"
              :key="item.province"
              :value="item.province"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属市" style="flex: 1;width: 100%" prop="city">
          <el-select v-model="form.city" placeholder="请选择活动区域" clearable>
            <el-option
              v-for="item in citys"
              :label="item.city"
              :key="item.city"
              :value="item.city"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item label="备注">
        <el-input v-model="form.note" type="textarea" />
      </el-form-item>
      <div v-if="id !== 0">
        <el-divider content-position="left">其他</el-divider>
        <div style="display: flex">
          <el-form-item label="平台ID" style="flex: 1">
            <el-input v-model="form.platformId" disabled />
          </el-form-item>
          <el-form-item label="创建人" style="flex: 1">
            <el-input v-model="form.createUser" disabled />
          </el-form-item>
          <el-form-item label="创建时间" style="flex: 1">
            <el-input v-model="form.createTime" disabled />
          </el-form-item>
          <el-form-item
            label="统一认证"
            style="flex: 1"
            v-if="form.centerLoginCode"
          >
            <el-input v-model="form.centerLoginCode" disabled />
          </el-form-item>
        </div>
      </div>
      <el-form-item style="text-align: center">
        <el-button
          type="primary"
          :loading="loading"
          @click="saveOrUpdate('form')"
        >
          提交
        </el-button>
        <!-- <el-button>取消</el-button>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addUser, queryById, updateUser } from '@/api/user'
import { getCitys, getProvince } from '@/api/city'
import Message from 'element-ui/packages/message/src/main'

export default {
  name: 'Add',
  data() {
    // 这里存放数据",
    return {
      loading: false,
      provinces: [],
      citys: [],
      form: {
        isTest: 0,
        dogcheck: 0
      },
      rules: {
        userAccount: [
          { required: true, message: '请输用户名', trigger: 'blur' }
        ],
        newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        blastPhoneCount: [
          { required: true, message: '每日反查限制条数', trigger: 'blur' }
        ],
        expireTime: [
          { required: true, message: '请选择到期日期', trigger: 'blur' }
        ],
        userLevel: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        canCreateUserNum: [
          { required: true, message: '请填写可创建用户数', trigger: 'blur' }
        ],
        province: [{ required: true, message: '请选择省', trigger: 'change' }],
        city: [{ required: true, message: '请选择市', trigger: 'change' }],
        key1: [{ required: true, message: '请输key1', trigger: 'blur' }],
        key2: [{ required: true, message: '请输key2', trigger: 'blur' }],
        key3: [{ required: true, message: '请输key3', trigger: 'blur' }],
        key4: [{ required: true, message: '请输key4', trigger: 'blur' }]
      }
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  props: {
    id: {
      type: Number
    }
  },
  // 监控data中的数据变化",
  watch: {
    id: {
      handler: function(val, oldVal) {
        this.resetData()
        if (val !== 0) {
          this.queryById(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  // 方法集合",
  methods: {
    queryById(id) {
      queryById(id)
        .then(resp => {
          console.info(resp)
          this.form = resp
        })
        .catch(err => {})
    },
    resetData() {
      this.form = {}
      this.$set(this.form, 'dogcheck', 0)
      this.$set(this.form, 'isTest', 0)
    },
    saveOrUpdate(formName) {
      this.$refs[formName].validate(valid => {
        this.loading = true
        if (valid) {
          if (this.id !== 0) {
            // 编辑
            updateUser(this.form)
              .then(res => {
                Message.success('更新成功！')
                this.resetData()
                this.$emit('addDone')
                this.loading = false
                this.loading = false
              })
              .catch(err => {
                this.loading = false
              })
          } else {
            //添加
            this.$set(this.form, 'userPassword', this.form.newPwd)
            addUser(this.form)
              .then(resp => {
                Message.success('创建成功！')
                this.resetData()
                this.$emit('addDone')
                this.loading = false
              })
              .catch(err => {
                this.loading = false
              })
          }
        } else {
          this.loading = false
          return false
        }
      })
    },
    randomPassword(length) {
      length = Number(length)
      // Limit length
      if (length < 6) {
        length = 6
      } else if (length > 16) {
        length = 16
      }
      const passwordArray = [
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        'abcdefghijklmnopqrstuvwxyz',
        '1234567890',
        '!@#$%&*.'
      ]
      var password = []
      let n = 0
      for (let i = 0; i < length; i++) {
        // If password length less than 9, all value random
        if (password.length < length - 4) {
          // Get random passwordArray index
          const arrayRandom = Math.floor(Math.random() * 4)
          // Get password array value
          const passwordItem = passwordArray[arrayRandom]
          // Get password array value random index
          // Get random real value
          const item =
            passwordItem[Math.floor(Math.random() * passwordItem.length)]
          password.push(item)
        } else {
          // If password large then 9, lastest 4 password will push in according to the random password index
          // Get the array values sequentially
          const newItem = passwordArray[n]
          const lastItem = newItem[Math.floor(Math.random() * newItem.length)]
          // Get array splice index
          const spliceIndex = Math.floor(Math.random() * password.length)
          password.splice(spliceIndex, 0, lastItem)
          n++
        }
      }
      this.$set(this.form, 'newPwd', password.join(''))
    },
    getProvince() {
      getProvince()
        .then(resp => {
          this.provinces = resp
        })
        .catch(err => {})
    },
    getCity() {
      this.citys = []
      this.$set(this.form, 'city', '')
      for (let i = 0; i < this.provinces.length; i++) {
        if (this.provinces[i].province === this.form.province) {
          getCitys(this.provinces[i].provinceNo)
            .then(res => {
              this.citys = res
            })
            .catch(err => {})
          break
        }
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.getProvince()
  }
}
</script>

<style></style>
