<template>
  <div>
    <div>
      <el-form
        ref="form"
        :model="condition"
        label-width="80px"
        style="padding: 0 20px 0 20px;"
      >
        <div style="display: flex;background-color: #ffffff;margin-top: 20px">
          <div style="display:flex; flex: 9">
            <el-form-item label="账号">
              <el-input
                type="text"
                v-model="condition.userAccount"
                placeholder="请输入账号"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="权限">
              <el-select
                v-model="condition.userLevel"
                placeholder="请选择权限"
                clearable
              >
                <el-option label="超级管理员" value="0"></el-option>
                <el-option label="用户管理员" value="1"></el-option>
                <el-option label="普通用户" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="加密狗">
              <el-select
                v-model="condition.dogcheck"
                placeholder="是否加密狗"
                clearable
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="测试用户">
              <el-select
                v-model="condition.isTest"
                placeholder="是否测试用户"
                clearable
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                type="text"
                v-model="condition.note"
                placeholder="请输入备注"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="submitSearch"
              >
                查询
              </el-button>
            </el-form-item>
          </div>
          <div style="flex: 1">
            <el-form-item>
              <el-button @click="addOrUpdate(0)">添加</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="app-container" style="margin-top: 2px">
      <el-table
        :data="tableData"
        height="675"
        border
        stripe
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column
          fixed
          prop="userAccount"
          label="登录账号"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="newPwd"
          label="密码"
          width="150"
        ></el-table-column>
        <el-table-column prop="userLevel" label="权限" width="120">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.userLevel === 0
                  ? 'primary'
                  : scope.row.userLevel === 1
                  ? 'warning'
                  : 'success'
              "
              disable-transitions
            >
              {{
                scope.row.userLevel === 0
                  ? '超级管理员'
                  : scope.row.userLevel === 1
                  ? '用户管理员'
                  : '普通用户'
              }}
            </el-tag>
          </template>
        </el-table-column>
        <!--<el-table-column
          prop="canQueryTimes"
          label="可查询次数"
          width="100">
        </el-table-column>-->
        <el-table-column
          prop="canCreateUserNum"
          label="创建账号数"
          width="100"
        ></el-table-column>
        <el-table-column prop="dogcheck" label="密狗" width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.dogcheck === 0 ? 'danger' : 'success'">
              {{ scope.row.dogcheck === 0 ? '否' : '是' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="blastPhoneCount"
          label="反查每日数"
          width="100"
        ></el-table-column>
        <el-table-column prop="isTest" label="测试用户" width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isTest === 0 ? 'danger' : 'success'">
              {{ scope.row.isTest === 0 ? '否' : '是' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="expireTime"
          label="到期日期"
          width="100"
        ></el-table-column>

        <el-table-column
          prop="note"
          label="备注"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="createUser"
          label="创建人"
          width="130"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="155">
          <template slot-scope="scope">
            <el-button
              @click="addOrUpdate(scope.row.id)"
              type="text"
              size="small"
            >
              编辑
            </el-button>
            <el-button
              @click="getPlatformInfo(scope.row.id)"
              type="text"
              size="small"
            >
              落查信息
            </el-button>
            <el-button
              @click="synchronousId(scope.row.id)"
              type="text"
              size="small"
            >
              同步ID
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="el-footer" style="text-align: center">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="50%"
    >
      <Add :id="currentId" v-on:addDone="addDone"></Add>
    </el-dialog>

    <el-dialog
      title="落查信息"
      :visible.sync="infoVisible"
      v-if="infoVisible"
      width="300px"
    >
      <info :infoData="infoData"></info>
    </el-dialog>
  </div>
</template>

<script>
import { platformInfo, syncUser, userPage } from '@/api/user'
import Add from '@/views/system/user/add'
import Info from '@/views/system/user/info'
export default {
  name: 'IndexPage',
  // import引入的组件需要注入到对象中才能使用",
  components: {
    Add,
    Info
  },
  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.queryPage()
  },
  data() {
    // 这里存放数据",
    return {
      infoVisible: false,
      infoData: {},
      currentId: 0,
      title: '',
      dialogVisible: false,
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],
      condition: {}
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 方法集合",
  methods: {
    synchronousId(id) {
      syncUser(id).then(res => {
        this.$message.success('更新成功！')
      })
    },
    queryPage(page = 1) {
      this.loading = true
      this.page = page
      userPage(this.page, this.limit, this.condition)
        .then(resp => {
          this.tableData = resp.items
          this.total = resp.all_count
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryPage()
    },
    handleCurrentChange(val) {
      this.queryPage(val)
    },
    submitSearch() {
      this.queryPage()
    },
    addOrUpdate(id) {
      this.currentId = id === 0 ? 0 : id
      this.title = id === 0 ? '添加' : '编辑'
      this.dialogVisible = true
    },
    addDone() {
      this.dialogVisible = false
      this.queryPage()
    },
    getPlatformInfo(id) {
      this.infoVisible = true
      platformInfo(id)
        .then(res => {
          this.infoData = res.data
        })
        .catch(err => {})
    }
  }
}
</script>

<style></style>
