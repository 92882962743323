<template>
  <div>
    <div v-if="loading">
      <div  style="display: flex;justify-content: center">
        <i class="el-icon-loading" style="font-size: 25px;"></i>
      </div>
      <div  style="display: flex;justify-content: center">
        <i style="font-size: 5px;">加载中请稍后...</i>
      </div>

    </div>

   <div v-if="!loading">
     <i class="el-icon-success" style="color: #2EB31D"></i>查询完成结果如下：
     <p><span>用户ID:</span>{{infoData.userid}}</p>
     <p><span>总积分:</span>{{infoData.total}}</p>
     <p><span>剩余积分:</span>{{infoData.integral}}</p>
     <p><span>开始时间:</span>{{infoData.created_at}}</p>
     <p><span>结束时间:</span>{{infoData.end_at}}</p>
   </div>
  </div>
</template>

<script>

export default {
  name: 'Info',

  props: { infoData: {
      type: Object
    }},
  data() {
    // 这里存放数据",
    return {
      loading : true
    }
  },
  // 监控data中的数据变化",
  watch: { infoData: {
      handler: function(val, oldVal) {
        this.loading = false
      },
      deep: true,
      immediate: true
    }},
  // 方法集合",
  methods: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {

  }
}


</script>

<style>

</style>
